import React from "react"

function Main() {
    
    return <div className="main-container">
        <h6 className="check-it-out">check out my stuff ^</h6>
        <img className="logo" src="adamrossbenton_transparent.png" alt=""/>
    </div>
}

export default Main